import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'react-free-style';
import { Avatar, sizes } from 'julius-frontend-components';
import TextInputInline from './text-input-inline';
import OutlookContactPersonChanges from './outlook-contact-person-changes';
import FormGroupSimpleArray from './form-group-simple-array';
import FormGroupAddress from './form-group-address';
import FormGroupEmail from './form-group-email';
import OutlookContactTransformer from './outlook-contact-transformer';

const {
  REACT_APP_FEATURE_OUTLOOK_EDIT_NAME,
  REACT_APP_FEATURE_OUTLOOK_EDIT_TITLE,
  REACT_APP_FEATURE_OUTLOOK_EDIT_COMPANY,
  REACT_APP_FEATURE_OUTLOOK_EDIT_NOTE,
} = process.env;
const features = {};
features.canEditName = REACT_APP_FEATURE_OUTLOOK_EDIT_NAME === true.toString();
features.canEditTitle = REACT_APP_FEATURE_OUTLOOK_EDIT_TITLE === true.toString();
features.canEditCompany = REACT_APP_FEATURE_OUTLOOK_EDIT_COMPANY === true.toString();
features.canEditNote = REACT_APP_FEATURE_OUTLOOK_EDIT_NOTE === true.toString();

const OutlookContactEditor = props => {
  const { styles, person, onSave, onCancel } = props;
  const outlookContact = OutlookContactTransformer(person);

  const [displayName, setDisplayName] = useState(outlookContact.displayName);
  const [fileAs, setFileAs] = useState(outlookContact.fileAs);
  const [jobTitle, setJobTitle] = useState(outlookContact.jobTitle);
  const [companyName, setCompanyName] = useState(outlookContact.companyName);
  const [mobilePhone, setMobilePhone] = useState(outlookContact.mobilePhone);
  const [businessPhones, setBusinessPhones] = useState(outlookContact.businessPhones);
  const [homePhones, setHomePhones] = useState(outlookContact.homePhones);
  const [emailAddresses, setEmailAddresses] = useState(outlookContact.emailAddresses);
  const [imAddresses, setImAddresses] = useState(outlookContact.imAddresses);
  const [businessAddress, setBusinessAddress] = useState(outlookContact.businessAddress);
  const [homeAddress, setHomeAddress] = useState(outlookContact.homeAddress);
  const [otherAddress, setOtherAddress] = useState(outlookContact.otherAddress);
  const [personalNotes, setPersonalNotes] = useState(outlookContact.personalNotes);

  return (
    <div className={styles.pane}>
      <div className={styles.headshotContainer}>
        <div className={styles.headshot}>
          <div
            className={styles.headshotImage}
            style={{
              backgroundImage: `url(${Avatar})`,
            }}
          />
        </div>
      </div>
      <div className={styles.column}>
        <div className={styles.actionLinks}>
          {onCancel && (
            <span
              className={styles.actionLink}
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </span>
          )}
          {onSave && (
            <span
              className={styles.actionLink}
              onClick={() => {
                const updatedContact = {
                  displayName,
                  fileAs,
                  jobTitle,
                  companyName,
                  mobilePhone,
                  businessPhones,
                  homePhones,
                  emailAddresses,
                  imAddresses,
                  businessAddress,
                  homeAddress,
                  otherAddress,
                  personalNotes,
                };
                const changes = OutlookContactPersonChanges(person, updatedContact);
                onSave(changes);
              }}
            >
              Save
            </span>
          )}
        </div>

        <form>
          <div className="form-group">
            {features.canEditName && (
              <TextInputInline
                label="Name"
                value={displayName}
                onChange={value => {
                  setDisplayName(value);
                  setFileAs(value); //keep fileAs in sync
                }}
                styles={styles}
              />
            )}
            {features.canEditTitle && (
              <TextInputInline
                label="Title"
                value={jobTitle}
                onChange={value => {
                  setJobTitle(value);
                }}
                styles={styles}
              />
            )}
            {features.canEditCompany && (
              <TextInputInline
                label="Company"
                value={companyName}
                onChange={value => {
                  setCompanyName(value);
                }}
                styles={styles}
              />
            )}
            <TextInputInline
              label="Mobile Phone"
              value={mobilePhone}
              onChange={value => {
                setMobilePhone(value);
              }}
              styles={styles}
            />
            {features.canEditNote && (
              <TextInputInline
                label="Personal Notes"
                value={personalNotes}
                onChange={value => {
                  setPersonalNotes(value);
                }}
                styles={styles}
              />
            )}
          </div>
          <FormGroupSimpleArray
            labelGroup="Business Phones"
            items={businessPhones}
            maxItems={2}
            onAdd={() => {
              setBusinessPhones([''].concat(businessPhones));
            }}
            onItemChanged={(index, value) => {
              businessPhones[index] = value;
              setBusinessPhones([].concat(businessPhones));
            }}
            onRemove={index => {
              businessPhones.splice(index, 1);
              setBusinessPhones([].concat(businessPhones));
            }}
            styles={styles}
            itemPlaceholder="Phone Number"
          />
          <FormGroupSimpleArray
            labelGroup="Home Phones"
            items={homePhones}
            maxItems={2}
            onAdd={() => {
              setHomePhones([''].concat(homePhones));
            }}
            onItemChanged={(index, value) => {
              homePhones[index] = value;
              setHomePhones([].concat(homePhones));
            }}
            onRemove={index => {
              homePhones.splice(index, 1);
              setHomePhones([].concat(homePhones));
            }}
            styles={styles}
            itemPlaceholder="Phone Number"
          />
          <FormGroupEmail
            items={emailAddresses}
            maxItems={3}
            onAdd={() => {
              setEmailAddresses([{ name: '', address: '' }].concat(emailAddresses));
            }}
            onItemChanged={(index, value) => {
              emailAddresses[index] = value;
              setEmailAddresses([].concat(emailAddresses));
            }}
            onRemove={index => {
              emailAddresses.splice(index, 1);
              setEmailAddresses([].concat(emailAddresses));
            }}
            styles={styles}
          />
          <FormGroupSimpleArray
            labelGroup="IM Addresses"
            items={imAddresses}
            maxItems={3}
            onAdd={() => {
              setImAddresses([''].concat(imAddresses));
            }}
            onItemChanged={(index, value) => {
              imAddresses[index] = value;
              setImAddresses([].concat(imAddresses));
            }}
            onRemove={index => {
              imAddresses.splice(index, 1);
              setImAddresses([].concat(imAddresses));
            }}
            styles={styles}
            itemPlaceholder="IM Address"
          />
          <FormGroupAddress
            labelGroup="Business Address"
            address={businessAddress}
            onChanged={address => {
              setBusinessAddress(Object.assign({}, address));
            }}
            styles={styles}
          />
          <FormGroupAddress
            labelGroup="Home Address"
            address={homeAddress}
            onChanged={address => {
              setHomeAddress(Object.assign({}, address));
            }}
            styles={styles}
          />
          <FormGroupAddress
            labelGroup="Other Address"
            address={otherAddress}
            onChanged={address => {
              setOtherAddress(Object.assign({}, address));
            }}
            styles={styles}
          />
        </form>
      </div>
    </div>
  );
};
OutlookContactEditor.propTypes = {
  person: PropTypes.object,
  styles: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};
const withStyles = styled({
  pane: {
    display: 'flex',
    padding: 10,
    [`@media ${sizes.mobileBreakpoint}`]: {
      flexDirection: 'column',
    },
  },
  column: {
    flex: 1,
    '&+&': {
      marginLeft: 40,
    },
    [`@media ${sizes.mobileBreakpoint}`]: {
      marginLeft: 0,
      marginRight: 0,
    },
    marginRight: 40,
  },
  actionLinks: {
    textAlign: 'end',
  },
  actionLink: {
    fontSize: '10px',
    textTransform: 'Uppercase',
    marginLeft: 10,
    cursor: 'pointer',
    color: '#007bff',
    '&:hover': { textDecoration: 'underline' },
    whiteSpace: 'nowrap',
  },
  headshotContainer: {
    flex: '0 1 auto',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
    marginRight: 40,
  },
  headshot: {
    borderRadius: 999,
    width: 100,
    height: 100,
    padding: 3,
    border: `1px solid #DEDEDE`,
    overflow: 'hidden',
  },
  headshotImage: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    borderRadius: 999,
  },
  label: {
    fontSize: '10px',
    textTransform: 'Uppercase',
    whiteSpace: 'nowrap',
  },
  input: {
    fontSize: '10px',
    marginBottom: 5,
    '&:hover': {
      border: '1px solid rgb(87, 140, 247)',
    },
  },
  formGroupLabel: {
    fontSize: '10px',
    textTransform: 'Uppercase',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
  },
});
export default withStyles(OutlookContactEditor);
