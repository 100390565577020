import React from 'react';
import PropTypes from 'prop-types';
import TextInputInline from './text-input-inline';

const FormGroupSimpleArray = props => {
  const {
    styles = {},
    labelGroup,
    labelAddItem,
    labelRemoveItem,
    items,
    onAdd,
    onRemove,
    onItemChanged,
    maxItems,
    itemPlaceholder,
  } = props;
  return (
    <div className="form-group">
      <div className="row">
        <div className="col-sm-2">
          <label className={styles.formGroupLabel}>{labelGroup || 'Items'}</label>
        </div>
        {(!maxItems || items.length < maxItems) && (
          <div className="col-sm-10">
            <span
              className={styles.actionLink}
              onClick={() => {
                onAdd && onAdd();
              }}
            >
              {labelAddItem || 'Add'}
            </span>
          </div>
        )}
      </div>

      {items.map((item, index) => {
        return (
          <div className="row" key={index}>
            <div className="col-sm-2" />
            <div className="col-sm-4 pr-0">
              <TextInputInline
                label=""
                value={item}
                onChange={value => {
                  onItemChanged && onItemChanged(index, value);
                }}
                placeholder={itemPlaceholder}
                styles={styles}
              />
            </div>
            <div className="col-sm-4 pl-0">
              <span
                className={styles.actionLink}
                onClick={() => {
                  onRemove && onRemove(index);
                }}
              >
                {labelRemoveItem || 'Remove'}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
FormGroupSimpleArray.propTypes = {
  items: PropTypes.array.isRequired,
  styles: PropTypes.object,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onItemChanged: PropTypes.func,
  maxItems: PropTypes.number,
  labelGroup: PropTypes.string,
  labelAddItem: PropTypes.string,
  labelRemoveItem: PropTypes.string,
  itemPlaceholder: PropTypes.string,
};

export default FormGroupSimpleArray;
