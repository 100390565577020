import React from 'react';
import classnames from 'classnames';
import { styled } from 'react-free-style';
import PropTypes from 'prop-types';

const SortableColumnHeader = props => {
  const { text, onClick, direction, styles } = props;
  const sortIconClass = direction ? (direction === 1 ? 'fa-caret-up' : 'fa-caret-down') : 'fa-sort';
  const selectedClass = direction ? styles.selected : styles.notSelected;
  return (
    <span
      id={`sortable-column-${text}`}
      className={styles.clickableHeaderText}
      onClick={() => {
        onClick && onClick(direction ? direction * -1 : 1);
      }}
    >
      {text}
      <i className={classnames('fa', 'fa-w', sortIconClass, selectedClass)} />
    </span>
  );
};
const withStyles = styled({
  notSelected: {
    color: 'silver',
    marginLeft: 5,
  },
  selected: {
    color: 'black',
    marginLeft: 5,
  },
  clickableHeaderText: {
    cursor: 'pointer',
    whiteSpace: 'noWrap',
  },
});
SortableColumnHeader.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  direction: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  styles: PropTypes.object,
};
export default withStyles(SortableColumnHeader);
