import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'react-free-style';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Avatar, DeskSelector, OnClickOutside, Search, colors } from 'julius-frontend-components';
import { createShortcuts, filterInputEvent, keyboard, stringifyKey } from '../support/keyboard';

const popoverBottom = styles => (
  <Popover id="popover-positioned-bottom" positionTop="100" title="Menu">
    <ul className={styles.menuList}>
      <li className={styles.menuItem}>
        <div
          onClick={() => {
            localStorage.removeItem('uta.auth');
            window.location = '/login';
          }}
        >
          <i className="fa fa-fw fa-sign-out" aria-hidden="true" />
          Sign Out
        </div>
      </li>
    </ul>
  </Popover>
);

class UserMenu extends Component {
  render() {
    const { styles, user } = this.props;

    return (
      <div className={styles.user}>
        <div className={styles.userContent}>
          <div className={styles.imageColumn}>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              delay={0}
              ref={ref => (this.trigger = ref)}
              onBlur={() => this.trigger.hide()}
              overlay={popoverBottom(styles)}
            >
              <OnClickOutside onClickOutside={() => this.trigger.hide()}>
                <div
                  className={styles.userImage}
                  style={{
                    backgroundImage: `url(${
                      user && user.personId && user.personId.profile_pic ? user.personId.profile_pic : Avatar
                    })`,
                  }}
                />
              </OnClickOutside>
            </OverlayTrigger>
          </div>
        </div>
      </div>
    );
  }
}

export class Header extends Component {
  state = {
    firstLoad: true,
  };

  keyboardListener = filterInputEvent(
    createShortcuts({
      [stringifyKey('/')]: e => {
        e.preventDefault();

        return this.searchInput && this.searchInput.focus();
      },
    })
  );

  componentDidMount() {
    keyboard.addListener(this.keyboardListener);
  }

  componentWillUnmount() {
    keyboard.removeListener(this.keyboardListener);
  }

  render() {
    const { styles, user, desk, desks = [], setDesk, searchText } = this.props;
    return (
      <div className={styles.headerContainer}>
        <div className={styles.leftColumn}>
          {desks.length > 0 ? (
            <DeskSelector desk={desk} deskIds={desks} setDesk={setDesk} />
          ) : (
            <span style={{ marginLeft: 220 }} />
          )}
        </div>
        <div className={styles.searchColumn}>{this.searchBar(styles, searchText)}</div>
        <div className={styles.userColumn}>
          <div className={styles.header}>
            <UserMenu styles={styles} user={user} />
          </div>
        </div>
      </div>
    );
  }

  searchBar(styles, searchText) {
    const { onSearchTextChanged } = this.props;
    return (
      <div className={styles.search}>
        <Search
          noDebounce={false}
          value={searchText}
          className={styles.searchContainer}
          inputClassName={styles.searchInput}
          onChange={value => {
            onSearchTextChanged && onSearchTextChanged(value ? value : '');
          }}
          onSubmit={value => {
            onSearchTextChanged && onSearchTextChanged(value ? value : '');
          }}
          inputRef={searchInput => {
            this.searchInput = searchInput;
            if (this.state.firstLoad) {
              this.searchInput.focus();
              this.setState({ firstLoad: false });
            }
          }}
        />
      </div>
    );
  }
}

const withStyles = styled({
  headerContainer: {
    background: colors.contentBackground,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    height: '60px',
    marginBottom: '10px',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',
    justifyContent: 'center',
  },
  search: {
    width: '100%',
  },
  searchContainer: {
    margin: '10px',
  },
  searchInput: {
    '&&': {
      border: `1px solid ${colors.border}`,
      background: colors.background,
    },
  },
  menuList: {
    listStyle: 'none',
    width: '200%',
    marginLeft: '-30px',
    marginRight: '15px',
    marginTop: '10px',
  },
  menuItem: {
    '&:hover': {
      textDecoration: 'underline',
    },
    cursor: 'pointer',
    padding: '5px',
  },
  menuIcon: {
    marginRight: '10px',
  },
  userContent: {
    marginLeft: '40px',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  userImage: {
    borderRadius: '50%',
    height: '35px',
    width: '35px',
    backgroundSize: 'cover',
    cursor: 'pointer',
  },
  imageColumn: {
    display: 'flex',
    flex: 0.4,
    marginRight: '10px',
    marginTop: '3px',
    flexDirection: 'column',
  },
  leftColumn: {
    display: 'flex',
    flex: 0.25,
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'flex-start',
  },
  searchColumn: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  userColumn: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: '5px',
  },
  settingLabel: {
    marginBottom: '0px',
  },
});

Header.propTypes = {
  styles: PropTypes.object,
  desk: PropTypes.object,
  user: PropTypes.object,
  onSearchTextChanged: PropTypes.func,
  desks: PropTypes.array,
  setDesk: PropTypes.func,
  searchText: PropTypes.string,
};
UserMenu.propTypes = {
  styles: PropTypes.object,
  user: PropTypes.object,
};
const headerWithStyles = withStyles(Header);

export default headerWithStyles;
