import { datadogRum } from '@datadog/browser-rum';

const HOST_MAP = {
  'contacts.unitedtalent.com': 'prod',
  'contacts.staging.unitedtalent.com': 'staging',
  'contacts.qa.unitedtalent.com': 'dev',
  localhost: 'local',
};

/**
 * Initializes data dog for the appropriate host (environment)
 *
 * @param { string } host:
 * @param { string[] } allowableEnvs: a list of allowable environments to track.
 */
export const initializeDataDog = (host, allowableEnvs) => {
  const env = HOST_MAP[host];
  if (allowableEnvs.includes(env)) {
    datadogRum.init({
      env,
      version: '0.3.1',
      service: 'contacts-united-talent',
      site: 'us3.datadoghq.com',
      applicationId: 'aa41f689-ff42-4899-8e06-98672ecae16c',
      clientToken: 'pub63945cc7192123522d44d27d597ad8b2',
      allowedTracingOrigins: ['https://api.unitedtalent.com', /https:\/\/.*\.unitedtalent\.com/],
      sampleRate: 100,
      replaySampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'allow',
    });
  }
};
