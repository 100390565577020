import React, { Component } from 'react';
import { styled } from 'react-free-style';
import PropTypes from 'prop-types';

import { sizes, contactNames, Section, Info, Address, ClientTeam, Avatar } from 'julius-frontend-components';

export class ClientProfile extends Component {
  render() {
    const { styles = {}, person, onEdit, baseProfileUrl } = this.props;
    const { contacts, addresses, internalTeam } = person;

    return (
      <div className={styles.pane}>
        <div className={styles.headshotContainer}>
          <div className={styles.headshot}>
            <div
              className={styles.headshotImage}
              style={{
                backgroundImage: `url(${person.profile_pic || Avatar})`,
              }}
            />
          </div>
        </div>
        <div className={styles.column}>
          {onEdit && (
            <div className={styles.editLink} onClick={() => onEdit(person)}>
              Edit
            </div>
          )}
          <Section title="Contact Info">
            {(contacts || []).map(contact => {
              if (contact && contact._id) {
                return (
                  <Info
                    key={contact._id}
                    name={contact.description ? contact.description : contactNames[contact.contactType]}
                    isPrimary={contact.primary}
                  >
                    {contact.contact}
                  </Info>
                );
              }
              return null;
            })}
            {(addresses || []).map(address => {
              if (address && address._id) {
                return (
                  <Info key={address._id} name={address.type}>
                    <Address address={address} />
                  </Info>
                );
              }
              return null;
            })}
          </Section>
        </div>
        <div className={styles.column}>
          {person.type === 'Client' && internalTeam && internalTeam.length > 0 && (
            <div className="mt-0 pt-0 card-body">
              <div className="row">
                <div className="m-0 ml-3 p-0" style={{ width: 600 }}>
                  <p>Client Team</p>
                  {internalTeam.map((relationship, index) => {
                    const agent = relationship.utaEmployee;
                    return (
                      <ClientTeam
                        contacts={agent.contacts}
                        assistants={agent.assistants}
                        department={agent.department}
                        id={agent._id}
                        name={agent.name}
                        profilePicUrl={agent.profile_pic}
                        index={index}
                        relationshipType={relationship.type.replace('Point Agent', 'ResponsibleAgent')}
                        allRelationshipsSize={internalTeam.length}
                        styles={styles}
                        baseProfileUrl={baseProfileUrl}
                        key={index}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}

          {person.type === 'Industry Contact' && internalTeam && internalTeam.length > 0 && (
            <div className="mt-0 pt-0 card-body">
              <div className="row">
                <div className="m-0 ml-3 p-0" style={{ width: 600 }}>
                  <p>Coverage Team</p>
                  {internalTeam.map((relationship, index) => {
                    const agent = relationship.utaEmployee;
                    return (
                      <ClientTeam
                        contacts={agent.contacts}
                        assistants={agent.assistants}
                        department={agent.department}
                        id={agent._id}
                        name={agent.name}
                        profilePicUrl={agent.profile_pic}
                        index={index}
                        relationshipType={relationship.type.replace('Point Agent', 'ResponsibleAgent')}
                        allRelationshipsSize={internalTeam.length}
                        styles={styles}
                        baseProfileUrl={baseProfileUrl}
                        key={index}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ClientProfile.propTypes = {
  person: PropTypes.object,
  onEdit: PropTypes.func,
  styles: PropTypes.object,
  baseProfileUrl: PropTypes.string,
};

const withStyles = styled({
  pane: {
    display: 'flex',
    padding: 10,
    [`@media ${sizes.mobileBreakpoint}`]: {
      flexDirection: 'column',
    },
  },
  column: {
    flex: 1,
    '&+&': {
      marginLeft: 40,
      [`@media ${sizes.mobileBreakpoint}`]: {
        marginLeft: 0,
      },
    },
  },
  headshotContainer: {
    flex: '0 1 auto',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
    marginRight: 40,
  },
  headshot: {
    borderRadius: 999,
    width: 100,
    height: 100,
    padding: 3,
    border: `1px solid #DEDEDE`,
    overflow: 'hidden',
  },
  headshotImage: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    borderRadius: 999,
  },
  profileLink: {
    fontSize: '10px',
    textTransform: 'Uppercase',
    float: 'right',
  },
  editLink: {
    fontSize: '10px',
    textTransform: 'Uppercase',
    float: 'right',
    cursor: 'pointer',
    color: '#007bff',
    '&:hover': { textDecoration: 'underline' },
  },
});

export default withStyles(ClientProfile);
