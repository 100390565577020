import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styled } from 'react-free-style';

import { colors, Profile } from 'julius-frontend-components';

import ContactRow from './contact-row';
import ClientProfile from './client-profile';
import SortableColumnHeader from './sortable-column-header';
import OutlookContactEditor from './outlook-contact-editor';

const BASE_PROFILE_URL = process.env.REACT_APP_PROFILE_URL;

export const ContactDetails = ({
  person,
  expanded,
  onEdit,
  styles = {},
  editing,
  onSave,
  onCancel,
  onExpandChanged,
}) => {
  const isClient = person.type === 'Client' || person.type === 'Industry Contact';
  const StaticProfileComponent = isClient ? (
    <ClientProfile person={person} onEdit={onEdit} baseProfileUrl={BASE_PROFILE_URL} />
  ) : (
    <Profile person={person} call={person} hidePic={false} onEdit={onEdit} />
  );
  return (
    <>
      <ContactRow person={person} expanded={expanded} onExpandChanged={onExpandChanged} />
      {expanded && (
        <tr key={`active:${person._id}`}>
          <td className={styles.pane} colSpan={7}>
            {editing ? (
              <OutlookContactEditor person={person} onSave={onSave} onCancel={onCancel} />
            ) : (
              <div>
                <div>{StaticProfileComponent}</div>
              </div>
            )}
          </td>
        </tr>
      )}
    </>
  );
};

class ContactTable extends Component {
  constructor(props) {
    super(props);
    this.state = { expanded: {}, editing: {} };
  }
  render() {
    const { styles, contacts, onSortChanged, sortColumn, sortDirection, onOutlookContactUpdated } = this.props;
    const { expanded, editing } = this.state;
    return (
      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.th} style={{ width: 0, textAlign: 'center' }}>
                <SortableColumnHeader
                  text="Type"
                  direction={sortColumn === 'Type' && sortDirection}
                  onClick={direction => {
                    onSortChanged && onSortChanged('Type', direction);
                  }}
                />
              </th>
              <th className={styles.th}>
                <SortableColumnHeader
                  text="Name"
                  direction={sortColumn === 'Name' && sortDirection}
                  onClick={direction => {
                    onSortChanged && onSortChanged('Name', direction);
                  }}
                />
              </th>
              <th className={styles.th}>
                <SortableColumnHeader
                  text="Title"
                  direction={sortColumn === 'Title' && sortDirection}
                  onClick={direction => {
                    onSortChanged && onSortChanged('Title', direction);
                  }}
                />
              </th>
              <th className={styles.th}>
                <SortableColumnHeader
                  text="Company"
                  direction={sortColumn === 'Company' && sortDirection}
                  onClick={direction => {
                    onSortChanged && onSortChanged('Company', direction);
                  }}
                />
              </th>
              <th className={styles.th}>
                <SortableColumnHeader
                  text="Phone"
                  direction={sortColumn === 'Phone' && sortDirection}
                  onClick={direction => {
                    onSortChanged && onSortChanged('Phone', direction);
                  }}
                />
              </th>
              <th className={styles.th} colSpan="2">
                <SortableColumnHeader
                  text="Email"
                  direction={sortColumn === 'Email' && sortDirection}
                  onClick={direction => {
                    onSortChanged && onSortChanged('Email', direction);
                  }}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {contacts.length > 0 &&
              contacts.map(person => {
                const onEdit =
                  person.type === 'Outlook' &&
                  (person => {
                    editing[person._id] = true;
                    this.setState({ editing });
                  });
                const onSave = updates => {
                  onOutlookContactUpdated && onOutlookContactUpdated(person._id, updates);
                  delete editing[person._id];
                  this.setState({ editing });
                };
                const onCancel = () => {
                  delete editing[person._id];
                  this.setState({ editing });
                };
                const onExpandChanged = () => {
                  if (expanded[person._id] !== undefined) {
                    delete expanded[person._id];
                  } else {
                    expanded[person._id] = true;
                  }
                  this.setState({ expanded });
                };
                return (
                  <ContactDetails
                    person={person}
                    expanded={expanded[person._id] !== undefined}
                    editing={editing[person._id] !== undefined}
                    onExpandChanged={onExpandChanged}
                    onCancel={onCancel}
                    onSave={onSave}
                    styles={styles}
                    onEdit={onEdit}
                    key={`row:${person._id}`}
                  />
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }
}

ContactTable.propTypes = {
  dispatch: PropTypes.func,
  contacts: PropTypes.array,
  styles: PropTypes.object,
  onSortChanged: PropTypes.func,
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.number,
  onOutlookContactUpdated: PropTypes.func,
};

const withStyles = styled({
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: 10,
  },
  th: {
    padding: 0,
    color: colors.contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  divider: {
    height: 20,
  },
  pane: {
    background: colors.contentBackground,
    borderTopColor: colors.border,
    borderBottomColor: colors.border,
    border: `1px solid ${colors.background}`,
    overflow: 'visible',
  },
});

export default withStyles(ContactTable);
