const { ContactSorter, contactSortFormatter, getPrimaryPhone } = require('./contact-sorter');

const personSortFormatter = sortField => {
  switch (sortField) {
    case 'Name':
      return person => {
        return person.name.trim().toUpperCase();
      };
    case 'Type':
      return person => {
        return person.type.toUpperCase();
      };
    case 'Title':
      return person => {
        return person.title ? person.title.trim().toUpperCase() : '';
      };
    case 'Company':
      return person => {
        return person.companyId ? person.companyId.name.trim().toUpperCase() : '';
      };
    case 'Phone':
      return person => {
        const phone = getPrimaryPhone(person.contacts);
        const phoneSanitizer = contactSortFormatter(sortField);
        return phone ? phoneSanitizer(phone) : '';
      };
    case 'Email':
      return person => {
        const emails = ContactSorter(person.contacts, sortField, 1);
        const email = emails.length > 0 && emails[0];
        const emailSanitizer = contactSortFormatter(sortField);
        return email ? emailSanitizer(email) : '';
      };
    default:
      throw new Error(`Unsupported Sort Field: ${sortField}`);
  }
};
const PeopleSorter = (people, sortField, sortDirection) => {
  const sortFunc = personSortFormatter(sortField);
  const sortedPeople = people.sort((a, b) => {
    const val1 = sortDirection === 1 ? sortFunc(a) : sortFunc(b);
    const val2 = sortDirection === 1 ? sortFunc(b) : sortFunc(a);
    return +(val1 > val2) || -(val1 < val2);
  });
  return sortedPeople;
};

module.exports = PeopleSorter;
