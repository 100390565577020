import {
  searchContactPortal,
  searchOutlook,
  personTypes,
  updateOutlookContact,
  searchRelationships,
} from 'julius-frontend-store';
import OutlookResultTransformer from './outlook-result-transformer';
import PersonResultTransformer from './person-result-transformer';

export const findPeople = ({
  dispatch,
  deskId,
  searchText,
  includeClients,
  includeIndustry,
  includeOutlook,
  hasContacts = false,
  contactDao = searchContactPortal,
  outlookDao = searchOutlook,
}) => {
  const types = [];
  const searchPromises = [];
  includeClients && types.push(personTypes.client);
  includeIndustry && types.push(personTypes.industryContact);

  if (types.length > 0) {
    const contactSearch = dispatch(contactDao(searchText, types, hasContacts))
      .then(results => {
        const people = results ? results.body.data : [];
        const transformedPeople = people.map(person => PersonResultTransformer(person));
        return transformedPeople;
      })
      .catch(err => {
        console.log(err);
        return [];
      });
    searchPromises.push(contactSearch);
  }
  if (includeOutlook) {
    const outlookSearch = dispatch(outlookDao(searchText, deskId))
      .then(results => {
        return results && results.body
          ? results.body.map(r => {
              return OutlookResultTransformer(r);
            })
          : [];
      })
      .catch(err => {
        console.log(err);
        return [];
      });
    searchPromises.push(outlookSearch);
  }
  return Promise.all(searchPromises).then(results => {
    const reduced = results.reduce((array, r) => {
      array.push(...r);
      return array;
    }, []);
    return reduced;
  });
};

export const updateOutlook = (dispatch, deskId, personId, update, _updateOutlookPerson = updateOutlookContact) => {
  return dispatch(_updateOutlookPerson(deskId, personId, update))
    .then(result => {
      return OutlookResultTransformer(result.body);
    })
    .catch(err => {
      console.log(err);
    });
};

export const findByDeskRelationship = (dispatch, desk, types) => {
  return dispatch(searchRelationships(desk.agentId._id, types)).then(results => {
    return ((results.body || {}).data || []).map(relationship => {
      return relationship.personId2;
    });
  });
};

export default { findPeople, updateOutlook, findByDeskRelationship };
