import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { styled } from 'react-free-style';
import { colors, elements } from 'julius-frontend-components';
import { Spinner, Branding } from 'components';
import { setDesk } from 'julius-frontend-store';

import ContactTable from '../components/contact-table';
import Header from '../components/header';
import CheckBox from '../components/checkbox';
import DropCapCheckBox from '../components/dropcap-checkbox';
import { findPeople, updateOutlook, findByDeskRelationship } from '../data/people-dao';
import peopleSorter from '../data/people-sorter';
import contactsSymbol from '../assets/logo/contacts_symbol_200x200.png';
import { profileUrl } from '../support/urls';
import { datadogRum } from '@datadog/browser-rum';

// Flag used to register what User is active in the application for DataDog.
let DATA_DOG_SET = false;

const SHOW_BANNER = process.env.REACT_APP_SHOW_BANNER;

// Flag used to mask quick search and my client sections of the UI
const HIDE_MY_CLIENTS = true;

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      includeClients: localStorage.getItem('includeClients') === true.toString(),
      includeOutlook: localStorage.getItem('includeOutlook') === true.toString(),
      includeIndustry: localStorage.getItem('includeIndustry') === true.toString(),
      hasContacts: true,
      isSearching: false,
      people: [],
      searchText: '',
      sortColumn: 'Name',
      sortDirection: 1,
      myClients: false,
      myClientsPoint: false,
      myClientsAgent: false,
    };
  }

  componentDidMount() {
    // if no configuration is active, DD should be set to true
    if (!datadogRum.getInitConfiguration()) {
      DATA_DOG_SET = true;
    }
  }

  componentDidUpdate() {
    const { user } = this.props;

    // Flag allows this to fire 1x per user-login, as desired.
    if (user && user.azure_id && !DATA_DOG_SET) {
      datadogRum.setUser({
        id: user.azure_id,
        name: `${user.last_name}, ${user.first_name}`,
        email: user.email,
      });
      datadogRum.startSessionReplayRecording();
      DATA_DOG_SET = true;
    }
  }

  findRelationships() {
    const { dispatch, desk } = this.props;
    const { state } = this;
    const { myClientsAgent, myClientsPoint, sortDirection, sortColumn } = state;
    const types = [];
    if (myClientsAgent) {
      types.push('Agent');
    }
    if (myClientsPoint) {
      types.push('ResponsibleAgent');
    }
    const typesArgument = types.length === 1 ? types : undefined;
    this.setState({ isSearching: true });
    return findByDeskRelationship(dispatch, desk, typesArgument).then(results => {
      const justClients = results.filter(result => result.type === 'Client');
      const sortedResults = peopleSorter(justClients, sortColumn, sortDirection);
      this.setState({ people: sortedResults, isSearching: false });
    });
  }

  search(params) {
    const { dispatch, desk } = this.props;
    const { state } = this;
    const { sortDirection, sortColumn } = state;
    const searchText = params.searchText || state.searchText;
    const includeClients = this._coalesce(params.includeClients, state.includeClients);
    const includeIndustry = this._coalesce(params.includeIndustry, state.includeIndustry);
    const includeOutlook = this._coalesce(params.includeOutlook, state.includeOutlook);
    const hasContacts = this._coalesce(params.hasContacts, state.hasContacts);

    const includeAll = !includeIndustry && !includeOutlook && !includeClients;
    this.setState({ myClients: false, myClientsAgent: false, myClientsPoint: false });
    //3 char min
    if (searchText.trim().length <= 2) {
      this.setState({ people: [] });
      return;
    }

    this.setState({ isSearching: true });

    return findPeople({
      dispatch,
      searchText,
      hasContacts,
      deskId: desk && desk._id,
      includeClients: includeClients || includeAll,
      includeIndustry: includeIndustry || includeAll,
      includeOutlook: desk && (includeOutlook || includeAll),
    }).then(results => {
      const sortedResults = peopleSorter(results, sortColumn, sortDirection);
      this.setState({ people: sortedResults, isSearching: false });
    });
  }

  _coalesce(obj1, obj2) {
    return obj1 === undefined ? obj2 : obj1;
  }

  _setInclude(includeObject) {
    const key = Object.keys(includeObject)[0];
    localStorage.setItem(key, includeObject[key]);
    this.setState(includeObject);
    this.search(includeObject);
  }

  async setDesk(desk) {
    const { dispatch } = this.props;
    const { myClients } = this.state;
    const _desk = desk || {};
    const resetQuickSearch = { myClients: false, myClientsPoint: false, myClientsAgent: false };
    if (myClients) {
      resetQuickSearch.people = [];
    }
    this.setState(resetQuickSearch);
    return dispatch(setDesk(_desk));
  }

  render() {
    const { styles = {}, desk, user, dispatch, desks } = this.props;
    const {
      searchText,
      includeIndustry,
      includeOutlook,
      includeClients,
      isSearching,
      people,
      sortColumn,
      sortDirection,
      myClients,
      myClientsAgent,
      myClientsPoint,
      hasContacts,
    } = this.state;

    const noRelationships = !desk || !desk.agentId || !desk.agentId.relationships || !desk.agentId.relationships[0];

    return (
      <div className={styles.container}>
        <Branding text="Contacts" symbol={contactsSymbol} showBanner={SHOW_BANNER} />
        <Header
          noDebounce={false}
          searchText={searchText}
          onSearchTextChanged={value => {
            const update = { searchText: value };
            this.setState(update);
            if (value.trim().length >= 3) {
              this.search(update);
            } else {
              this.setState({ people: [] });
            }
          }}
          desk={desk}
          desks={desks}
          user={user}
          setDesk={desk => this.setDesk(desk)}
        />
        <div className={styles.content}>
          <div className={styles.filterColumn}>
            <h5 style={{ fontSize: '18px' }}>Filters</h5>
            <DropCapCheckBox
              text="Clients"
              onCheckChanged={checked => {
                const update = { includeClients: checked };
                this._setInclude(update);
              }}
              checked={includeClients}
              capBackgroundColor={elements.clientMarker.background}
              capTextColor={elements.clientMarker.color}
            />
            <DropCapCheckBox
              text="Industry"
              onCheckChanged={checked => {
                const update = { includeIndustry: checked };
                this._setInclude(update);
              }}
              checked={includeIndustry}
              capBackgroundColor={elements.industryMarker.background}
              capTextColor={elements.industryMarker.color}
            />
            <DropCapCheckBox
              text="Outlook"
              onCheckChanged={checked => {
                const update = { includeOutlook: checked };
                this._setInclude(update);
              }}
              checked={includeOutlook}
              capBackgroundColor={elements.outlookMarker.background}
              capTextColor={elements.outlookMarker.color}
              enabled={desks.length > 0}
            />
            <div style={{ borderTop: '1px solid rgb(204, 204, 204)', marginTop: '20px', paddingTop: '20px' }}>
              <CheckBox
                text="Has Phone or Email"
                checked={hasContacts}
                containerStyle={{ paddingLeft: '10px' }}
                enabled={desks.length > 0}
                onCheckChanged={checked => {
                  const update = { hasContacts: checked };
                  this.setState(update);
                  this.search(update);
                }}
              />
            </div>
            {!HIDE_MY_CLIENTS && (
              <>
                <h5
                  style={{
                    borderTop: '1px solid #ccc',
                    marginTop: 20,
                    paddingTop: 20,
                    color: noRelationships ? '#ccc' : '',
                    fontSize: '18px',
                  }}
                >
                  Quick Search
                </h5>
                <button
                  disabled={noRelationships}
                  style={{
                    padding: 5,
                    marginTop: 10,
                    marginBottom: 10,
                    background: noRelationships ? '' : '#fff',
                    border: `1px solid ${noRelationships ? '#999' : '#28292A'}`,
                    cursor: noRelationships ? 'not-allowed' : 'pointer',
                  }}
                  onClick={() => {
                    this.setState(
                      {
                        myClients: true,
                        searchText: '',
                        includeClients: false,
                        includeIndustry: false,
                        includeOutlook: false,
                        hasContacts: false,
                      },
                      this.findRelationships
                    );
                  }}
                >
                  My Clients
                </button>
                <CheckBox
                  text="I am a point agent on"
                  enabled={myClients}
                  checked={myClientsPoint}
                  onCheckChanged={() => {
                    this.setState({ myClientsPoint: !myClientsPoint }, this.findRelationships);
                  }}
                />
                <CheckBox
                  text="I am an agent on"
                  enabled={myClients}
                  checked={myClientsAgent}
                  onCheckChanged={() => {
                    this.setState({ myClientsAgent: !myClientsAgent }, this.findRelationships);
                  }}
                />
                {noRelationships || !(desk.agentId && desk.agentId._id) ? (
                  <div className={styles.manageMyClientsDisabled}>
                    Manage My Clients
                    <i style={{ marginLeft: '5px' }} className="fa fa-angle-double-right" />
                  </div>
                ) : (
                  <a
                    href={`${profileUrl}/${desk.agentId._id}?view=team`}
                    className={styles.manageMyClients}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Manage My Clients
                    <i style={{ marginLeft: '5px' }} className="fa fa-angle-double-right" />
                  </a>
                )}
              </>
            )}
          </div>
          <div className={styles.tableColumn}>
            {isSearching ? (
              <div className={styles.spinner}>
                <Spinner size={60} style={{ paddingTop: 30 }} />
              </div>
            ) : (
              <div className={styles.gridContainer}>
                {people.length > 0 && (
                  <ContactTable
                    contacts={people}
                    user={user}
                    sortColumn={sortColumn}
                    sortDirection={sortDirection}
                    onSortChanged={(column, direction) => {
                      const sortedPeople = peopleSorter(people, column, direction);
                      this.setState({ sortColumn: column, sortDirection: direction, people: sortedPeople });
                    }}
                    onOutlookContactUpdated={(personId, updates) => {
                      return updateOutlook(dispatch, desk._id, personId, updates).then(result => {
                        const personIndex = people
                          .map(p => {
                            return p._id;
                          })
                          .indexOf(personId);
                        people[personIndex] = result;
                        const sortedPeople = peopleSorter(people, sortColumn, sortDirection);
                        this.setState({ people: sortedPeople });
                      });
                    }}
                  />
                )}
                {people.length === 0 && (
                  <div className={styles.searchResults}>
                    {myClients
                      ? `No ${myClientsPoint ? 'Point' : ''} Agent Relationships`
                      : !searchText || searchText.trim().length < 3
                      ? 'Searches Must Include 3 or More Characters'
                      : 'No Search Results Found'}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const withStyles = styled({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.background,
    fontFamily: 'aktiv-grotesk, sans-serif',
    height: '100%',
  },
  gridContainer: {
    width: '100%',
    padding: '0 10px',
    paddingRight: 20,
    alignItems: 'left',
  },
  searchResults: {
    textAlign: 'center',
    fontWeight: '700',
    paddingTop: 100,
    fontSize: 20,
  },
  spinner: {
    display: 'flex',
    paddingTop: 100,
    justifyContent: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  filterColumn: {
    display: 'flex',
    flex: 0.1,
    flexDirection: 'column',
    padding: 20,
    height: '100%',
    marginTop: -10,
  },
  tableColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    overflow: 'visible',
  },
  manageMyClients: {
    '&:hover': {
      textDecoration: 'underline',
    },
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 500,
    color: '#2F80ED',
    textAlign: 'right',
    marginTop: '5px',
  },
  manageMyClientsDisabled: {
    fontSize: '12px',
    fontWeight: 500,
    color: '#AAA',
    textAlign: 'right',
    marginTop: '5px',
  },
});

Contacts.propTypes = {
  dispatch: PropTypes.func,
  styles: PropTypes.object,
  contacts: PropTypes.object,
  desk: PropTypes.object,
  desks: PropTypes.array,
  user: PropTypes.object,
  outlook: PropTypes.object,
};

const withState = connect(store => {
  const { user, desk } = store;
  const desks = desk.available;
  const props = { user, desk: desk.current, desks };
  return props;
});

export default withState(withStyles(Contacts));
