const _getAddresses = person => {
  const addresses = [];
  ['homeAddress', 'otherAddress', 'businessAddress'].forEach(address => {
    let type = '';

    switch (address) {
      case 'homeAddress':
        type = 'Home';
        break;
      case 'businessAddress':
        type = 'Work';
        break;
      default:
        type = 'Unknown';
        break;
    }

    if (person[address] && person[address].street) {
      addresses.push({
        _id: Math.random(),
        address: person[address].street,
        state: person[address].state,
        city: person[address].city,
        country: person[address].countryOrRegion,
        zip: person[address].postalCode,
        type,
      });
    }
  });
  return addresses;
};

const _getContacts = person => {
  const contacts = [];

  person.emailAddresses.forEach(a => {
    contacts.push({
      _id: Math.random(),
      contactType: 'Email',
      contact: a.address,
      original: a,
    });
  });

  person.businessPhones.forEach(p => {
    contacts.push({
      _id: Math.random(),
      contactType: 'Office Phone',
      contact: p,
    });
  });

  person.homePhones.forEach(p => {
    contacts.push({
      _id: Math.random(),
      contactType: 'Home Phone',
      contact: p,
    });
  });

  if (person.mobilePhone) {
    contacts.push({
      _id: Math.random(),
      contactType: 'Mobile Phone',
      contact: person.mobilePhone,
    });
  }

  person.imAddresses.forEach(im => {
    contacts.push({
      _id: Math.random(),
      contactType: 'Message',
      contact: im,
    });
  });

  return contacts;
};

const OutlookResultTransformer = result => {
  return {
    _id: result.id,
    name: result.displayName,
    title: result.jobTitle,
    companyId: { name: result.companyName || '' },
    contacts: _getContacts(result),
    addresses: _getAddresses(result),
    note: result.personalNotes,
    outlook: true,
    type: 'Outlook',
  };
};

module.exports = OutlookResultTransformer;
