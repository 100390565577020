export const PersonResultTransformer = result => {
  const person = { ...result };
  delete person.title;
  const employer = (person.groupMembership || []).find(employer => employer.primary);
  if (employer) {
    person.title = employer.title;
    person.companyId = employer.group;
  }

  return person;
};

export default PersonResultTransformer;
