import React from 'react';
import PropTypes from 'prop-types';

const DropCapCheckBox = props => {
  const { checked, onCheckChanged, text, capBackgroundColor, capTextColor, enabled = true } = props;
  const firstLetter = text && text.charAt(0).toUpperCase();
  const icon = checked ? 'fa-check-square' : 'fa-square-o';
  const disabledColor = '#AAA';
  return (
    <div
      style={{
        paddingLeft: 10,
        fontSize: 20,
        fontWeight: 300,
        overflow: 'auto',
        whiteSpace: 'nowrap',
        cursor: enabled ? 'pointer' : 'not-allowed',
      }}
      onClick={() => {
        enabled && onCheckChanged && onCheckChanged(!checked);
      }}
    >
      <span>
        <i className={`fa ${icon} fa-w`} style={enabled ? {} : { color: disabledColor }} />
      </span>
      {firstLetter && (
        <span
          onClick={() => {}}
          style={{
            cursor: enabled ? 'pointer' : 'not-allowed',
            color: capTextColor,
            backgroundColor: enabled ? capBackgroundColor : disabledColor,
            fontWeight: 400,
            fontSize: 10,
            padding: 2,
            verticalAlign: 'middle',
            textAlign: 'center',
            marginRight: 3,
            marginLeft: 3,
            marginTop: -3,
            display: 'inline-block',
            height: 18,
            width: 16,
          }}
        >
          {firstLetter}
        </span>
      )}
      <span style={{ fontSize: 16, fontWeight: checked ? 400 : 300, color: enabled ? '' : disabledColor }}>{text}</span>
    </div>
  );
};
DropCapCheckBox.propTypes = {
  text: PropTypes.string,
  checked: PropTypes.bool,
  onCheckChanged: PropTypes.func,
  capBackgroundColor: PropTypes.string,
  capTextColor: PropTypes.string,
  enabled: PropTypes.bool,
};
export default DropCapCheckBox;
