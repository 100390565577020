import React from 'react';
import PropTypes from 'prop-types';

import { styled } from 'react-free-style';

const CheckBox = ({ checked, onCheckChanged, text, enabled = true, containerStyle = {}, styles = {} }) => {
  const colorStyle = enabled ? {} : { color: '#aaa' };
  return (
    <div
      style={{
        ...{ cursor: enabled ? 'pointer' : 'not-allowed' },
        ...containerStyle,
      }}
      className={styles.container}
      onClick={() => {
        enabled && onCheckChanged && onCheckChanged(!checked);
      }}
    >
      <span className={styles.icon}>
        <i style={colorStyle} className={`fa ${checked ? 'fa-check-square' : 'fa-square-o'} fa-w`} />
      </span>
      <span className={styles.text} style={colorStyle}>
        {text}
      </span>
    </div>
  );
};

const withStyles = styled({
  container: {
    paddingLeft: 6,
    fontSize: 16,
    fontWeight: 300,
    overflow: 'auto',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
  },
  icon: { fontSize: '20px' },
  text: {
    fontSize: 16,
    verticalAlign: 'top',
    paddingBottom: 2,
    paddingLeft: 4,
  },
});

CheckBox.propTypes = {
  text: PropTypes.string,
  checked: PropTypes.bool,
  enabled: PropTypes.bool,
  onCheckChanged: PropTypes.func,
  containerStyle: PropTypes.object,
  styles: PropTypes.object,
};

export default withStyles(CheckBox);
