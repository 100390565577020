import * as React from 'react';
import PropTypes from 'prop-types';
import { helpers, styled } from 'react-free-style';

import { TypeIcon, colors, elements } from 'julius-frontend-components';
import { VerificationIcon, fromStringToE164, fromE164ToDisplay } from 'components';

import { getPrimaryEmail, getPrimaryPhone } from '../data/contact-sorter';
import { profileUrl } from '../support/urls';

export const ContactName = ({ person, styles }) => {
  const { type, _id, name, verifiedBy, verifiedOn } = person;
  const isClient = type === 'Client' || type === 'Industry Contact';
  return isClient ? (
    <span className={styles.linkContainer}>
      <a href={`${profileUrl}/${_id}`} className={styles.link} target="_blank" rel="noopener noreferrer">
        {name}
      </a>
      {verifiedBy && verifiedOn && (
        <VerificationIcon
          verifiedBy={`${verifiedBy.first_name} ${verifiedBy.last_name}`}
          verifiedOn={verifiedOn}
          identifier={_id}
          includeExplanation
        />
      )}
    </span>
  ) : (
    name
  );
};

const ContactRow = ({ styles = {}, person, expanded, onExpandChanged }) => {
  const email = getPrimaryEmail(person.contacts);
  const phone = getPrimaryPhone(person.contacts);
  const phoneDisplay =
    phone && phone.contact
      ? fromE164ToDisplay(fromStringToE164(phone.contact), navigator.language.split('-')[1], ' x')
      : '';
  return (
    <tr className={styles.tr}>
      <td style={{ textAlign: 'center' }}>
        <TypeIcon split entity={person} customStyles={{ fontSize: 12, width: 24, height: 24 }} />
      </td>
      <td>
        <span className={styles.person}>
          <ContactName person={person} styles={styles} />
        </span>
      </td>
      <td>
        <span className={styles.person}>{person.title}</span>
      </td>
      <td>
        <span className={styles.person}>{person.companyId ? person.companyId.name : ''}</span>
      </td>
      <td>
        {phone && phone.contactType === 'Office Phone' && (
          <i style={{ fontSize: 16, width: 18 }} className="fa fa-building" />
        )}
        {phone && phone.contactType === 'Mobile Phone' && (
          <i style={{ fontSize: 18, width: 18 }} className="fa fa-mobile" />
        )}
        {phone && phone.contactType === 'Home Phone' && (
          <i style={{ fontSize: 18, width: 18 }} className="fa fa-phone" />
        )}
        <span className={styles.person}>{phoneDisplay}</span>
      </td>
      <td>
        <span className={styles.person}>{email && email.contact}</span>
      </td>
      <td>
        <span
          className={styles.person}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            onExpandChanged && onExpandChanged(!expanded);
          }}
        >
          <i style={{ fontSize: 18, width: 18 }} className={`fa fa-chevron-${expanded ? 'up' : 'down'}`} />
        </span>
      </td>
    </tr>
  );
};

const withStyles = styled({
  linkContainer: { display: 'flex' },
  link: { marginRight: '10px' },
  title: {
    marginLeft: '10px',
    fontSize: '10px',
    fontWeight: 300,
    width: '97%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tr: {
    '> td': {
      padding: '2px',
      fontSize: '11px',
      fontWeight: 300,
      color: colors.text,
      verticalAlign: 'middle',
    },
    '&:last-child': {
      borderBottom: `1px solid ${colors.border}`,
    },
    backgroundColor: colors.contentBackground,
    border: `1px solid ${colors.background}`,
    borderTopColor: colors.border,
    borderBottom: 0,
  },
  person: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 'normal',
    textOverflow: 'ellipsis',
    fontSize: '12px',
    color: '#4A4A4A',
  },
  data: {
    display: 'flex',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  button: helpers.merge(elements.button, elements.actionable, {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    margin: '5px',
  }),
  defaultButton: {
    borderColor: '#000',
  },
  redButton: {
    borderColor: colors.invalidBorder,
  },
  greenButton: {
    borderColor: colors.green,
  },
});

ContactName.propTypes = {
  person: PropTypes.object,
  styles: PropTypes.object,
};

ContactRow.propTypes = {
  person: PropTypes.object,
  styles: PropTypes.object,
  expanded: PropTypes.bool,
  onExpandChanged: PropTypes.func,
};

export default withStyles(ContactRow);
