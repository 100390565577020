const _phoneTypePriority = ['Office Phone', 'Assistant Phone', 'Mobile Phone', 'Home Phone'];
const _emailTypePriority = ['Email', 'Assistant Email'];
const contactSortFormatter = sortField => {
  switch (sortField) {
    case 'Phone':
      return contact => {
        return contact.contact ? contact.contact.match(/\d+/g) : '';
      };
    case 'Email':
      return contact => {
        return contact.contact ? contact.contact.trim().toUpperCase() : '';
      };
    default:
      throw new Error(`Unsupported Sort Field: ${sortField}`);
  }
};
const _getContactsFilterFunc = type => {
  switch (type) {
    case 'Phone':
      return contact => {
        return _phoneTypePriority.some(p => {
          return p === contact.contactType;
        });
      };
    case 'Email':
      return contact => {
        return _emailTypePriority.some(p => {
          return p === contact.contactType;
        });
      };
    default:
      throw new Error(`Unsupported Filter Type: ${type}`);
  }
};
const ContactsFilter = (contacts, type) => {
  const filterFunc = _getContactsFilterFunc(type);
  return contacts
    ? contacts.filter(c => {
        return filterFunc(c);
      })
    : [];
};
const ContactSorter = (contacts, sortField, sortDirection) => {
  const sortFunc = contactSortFormatter(sortField);
  const contactToSort = ContactsFilter(contacts, sortField);
  const sortedContacts = contactToSort.sort((a, b) => {
    const val1 = sortDirection === 1 ? sortFunc(a) : sortFunc(b);
    const val2 = sortDirection === 1 ? sortFunc(b) : sortFunc(a);
    return +(val1 > val2) || -(val1 < val2);
  });
  return sortedContacts;
};

const getPrimaryPhone = contacts => {
  const phoneContacts = ContactsFilter(contacts, 'Phone');
  const sortedContacts = phoneContacts.sort((a, b) => {
    const aIsPrimary = a.primary ? 1 : 0;
    const bIsPrimary = b.primary ? 1 : 0;
    const considerPrimary = aIsPrimary || bIsPrimary;
    const val1 = considerPrimary ? aIsPrimary * -1 : _phoneTypePriority.indexOf(a.contactType);
    const val2 = considerPrimary ? bIsPrimary * -1 : _phoneTypePriority.indexOf(b.contactType);
    return +(val1 > val2) || -(val1 < val2);
  });

  return sortedContacts[0];
};
const getPrimaryEmail = contacts => {
  const emailContacts = ContactsFilter(contacts, 'Email');
  const sortedContacts = emailContacts.sort((a, b) => {
    const aIsPrimary = a.primary ? 1 : 0;
    const bIsPrimary = b.primary ? 1 : 0;
    const considerPrimary = aIsPrimary || bIsPrimary;
    const val1 = considerPrimary ? aIsPrimary * -1 : _emailTypePriority.indexOf(a.contactType);
    const val2 = considerPrimary ? bIsPrimary * -1 : _emailTypePriority.indexOf(b.contactType);
    return +(val1 > val2) || -(val1 < val2);
  });

  return sortedContacts[0];
};

module.exports = { ContactSorter, contactSortFormatter, getPrimaryPhone, getPrimaryEmail };
